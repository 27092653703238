<template>
  <section class="personal-wrap">
    <section class="personal-header">
      <h3 class="title"><i></i>基本信息</h3>
      <p class="edit" @click="editVisible = true" v-if="!editVisible"><i></i>编辑</p>
    </section>

    <section class="personal-main">
      <ul class="personal-info" v-if="!editVisible">
        <li class="info-item">
          <p>
            <span class="left-item">头像：</span><span class="right-item photo"><img :src="userInfo.avatar | removeProtocolHeader" alt="" v-show="userInfo.avatar" /></span>
          </p>
        </li>
        <li class="info-item">
          <p>
            <span class="left-item">用户名：</span><span class="right-item">{{ userInfo.nickname }}</span>
          </p>
        </li>
        <li class="info-item">
          <p>
            <span class="left-item">性别：</span><span class="right-item">{{ userInfo.gender | formatGender }}<i class="gender" :class="userInfo.gender | formatGenderClass"></i></span>
          </p>
        </li>
      </ul>

      <section class="edit-info" v-else>
        <el-form :model="infoRuleForm" :rules="rules" ref="infoRuleForm" label-width="72px" class="demo-infoRuleForm">
          <el-form-item label="头像：" class="photo">
            <img :src="infoRuleForm.avatar | removeProtocolHeader" :alt="infoRuleForm.nickname" @click="userIconDialogVisible = !isMjingzhuhao" />
          </el-form-item>
          <el-form-item label="用户名：" prop="nickname" class="nickname">
            <el-input v-model="infoRuleForm.nickname" placeholder="请输入姓名" :disabled="isMjingzhuhao" maxlength="8" show-word-limit></el-input>
          </el-form-item>
          <el-form-item label="性别：" prop="gender">
            <el-radio-group v-model="infoRuleForm.gender" :disabled="isMjingzhuhao">
              <el-radio label="1">男</el-radio>
              <el-radio label="2">女</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="infoSubmitForm('infoRuleForm')" :disabled="isMjingzhuhao">完成</el-button>
            <el-button @click="editVisible = false">取消</el-button>
          </el-form-item>
        </el-form>
      </section>
    </section>

    <!-- 用户头像列表 -->
    <section class="personal-user-icon">
      <el-dialog :visible.sync="userIconDialogVisible" :show-close="false">
        <section class="user-icon-lists-wrap">
          <ul class="user-icon-lists">
            <li class="item" v-for="item in userIconList" :key="item.name">
              <img class="user-icon" :src="item.url | removeProtocolHeader" alt="" @click="activeUserIcon = item.url" />
              <p class="active-icon" v-if="item.url === activeUserIcon"></p>
            </li>
          </ul>
        </section>
        <span slot="footer" class="dialog-footer">
          <el-button @click="userIconDialogVisible = false">取消</el-button>
          <el-button type="primary" @click="userIconClose">确定</el-button>
        </span>
      </el-dialog>
    </section>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import { axiosIconList, editUserInfo } from '@/api/axios.request.js';
import { setUserInfo } from '@/util/index.js';
import { formatGender, formatGenderClass, removeProtocolHeader } from '@/util/methods.js';
import { validate } from '@/util/form.js';

export default {
  name: 'personal',
  data() {
    return {
      editVisible: false, // 编辑状态
      ruleFormName: 'infoRuleForm', // 当前使用的表单名称，用于表单自定义校验时获取相应的数据
      infoRuleForm: {
        avatar: '',
        nickname: '',
        gender: ''
      },
      // 明珠号不可编辑
      isMjingzhuhao: false,
      // 用户头像列表 Dialog
      userIconDialogVisible: false,
      // 用户头像列表
      userIconList: [],
      activeUserIcon: ''
    };
  },
  computed: {
    ...mapState(['userInfo', 'userEdit', 'mingzhuhaoDialogVisible', 'loginStatus'])
  },
  watch: {
    loginStatus: {
      handler(curVal) {
        if (!curVal) {
          this.editVisible = false;
          this.infoRuleForm = {
            avatar: '',
            nickname: '',
            gender: ''
          };
        }
      },
      immediate: true
    },
    userInfo: {
      handler(curVal) {
        if (curVal) {
          this.activeUserIcon = curVal.avatar;

          this.infoRuleForm.avatar = curVal.avatar;
          this.infoRuleForm.nickname = curVal.nickname;
          this.infoRuleForm.gender = Number(curVal.gender) === 0 ? '1' : String(curVal.gender);

          this.isMjingzhuhao = /MZ_MEDIA/.test(curVal.register_source);
        }
      },
      deep: true,
      immediate: true
    },
    userEdit: {
      handler(curVal) {
        if (curVal) {
          this.editVisible = true;

          this.$store.commit('SET_USEREDIT', false);
        }
      },
      immediate: true
    },
    editVisible: {
      handler(curVal) {
        if (curVal && this.userIconList.length <= 0) {
          if (this.isMjingzhuhao) {
            this.$store.commit('SET_MINGZHUHAODIALOGVISIBLE', true);
          }
          this.getUserIconList();
        }
      },
      immediate: true
    }
  },
  filters: { formatGender, formatGenderClass, removeProtocolHeader },
  methods: {
    ...validate,
    // 获取用户头像列表
    getUserIconList() {
      let _this = this;

      axiosIconList().then((result) => {
        const { data } = result;

        _this.userIconList = data.result;
      });
    },
    // 修改用户头像
    userIconClose() {
      this.userIconDialogVisible = false;
      this.infoRuleForm.avatar = this.activeUserIcon;
    },
    // 修改用户信息
    infoSubmitForm(formName) {
      let _this = this;

      _this.$refs[formName].validate((valid) => {
        if (valid) {
          editUserInfo({
            ..._this.infoRuleForm
          }).then((response) => {
            const { code, result, message } = response.data;

            if (code === 200) {
              _this.editVisible = false;

              let tempUserInfo = _this.userInfo;

              tempUserInfo.avatar = result.avatar;
              tempUserInfo.nickname = result.nickname;
              tempUserInfo.gender = result.gender;

              _this.$store.commit('SET_USERINFO', tempUserInfo);

              setUserInfo({
                data: tempUserInfo,
                monthLogin: true
              });
            } else {
              _this.msgError(message);
            }
          });
        } else {
          return false;
        }
      });
    }
  },
  created() {
    this.rules();
  }
};
</script>

<style lang="scss" scoped>
.personal-wrap {
  .personal-header {
    position: relative;
    padding: 15px;
    font-size: 0;
    border-bottom: 1px solid #f4f4f4;

    .title {
      height: 22px;
      font-size: 16px;
      line-height: 22px;

      i {
        display: inline-block;
        margin-right: 5px;
        width: 22px;
        height: 22px;
        background: url('../../assets/image/icon1.png') -2px -97px no-repeat;
        vertical-align: top;
      }
    }

    .edit {
      position: absolute;
      top: 16px;
      right: 15px;
      height: 20px;
      color: #1985ff;
      font-size: 14px;
      line-height: 20px;
      cursor: pointer;

      i {
        display: inline-block;
        margin-top: 3px;
        margin-right: 5px;
        width: 14px;
        height: 14px;
        background: url('../../assets/image/edit.png') center center no-repeat;
        vertical-align: top;
      }
    }
  }

  .personal-main {
    .personal-info {
      padding: 15px;

      .info-item {
        margin-bottom: 15px;

        span,
        i {
          display: inline-block;
          vertical-align: middle;
        }
      }

      .left-item {
        margin-right: 12px;
        width: 60px;
        height: 40px;
        text-align: right;
        color: #333333;
        font-size: 14px;
        line-height: 40px;
      }

      .right-item {
        height: 40px;
        color: #888888;
        font-size: 14px;
        line-height: 40px;

        &.photo {
          width: 54px;
          height: 54px;
          border-radius: 50%;
          overflow: hidden;

          img {
            display: block;
            width: 100%;
            height: 100%;
          }
        }

        .gender {
          display: inline-block;
          margin-top: 10px;
          margin-left: 5px;
          width: 20px;
          height: 20px;
          vertical-align: top;

          &[class*='men'] {
            background: url('../../assets/image/gender.png') no-repeat;
          }

          &.men {
            background-position: 0 0;
          }

          &.women {
            background-position: 0 -20px;
          }
        }
      }
    }

    .edit-info {
      padding: 15px;

      /deep/ .el-form {
        .el-form-item {
          margin-bottom: 15px;
        }

        .photo {
          .el-form-item__label {
            height: 54px;
            line-height: 54px;
          }

          img {
            display: block;
            width: 54px;
            height: 54px;
            border-radius: 50%;
            cursor: pointer;
          }
        }

        .nickname {
          .el-input {
            width: 200px;
          }
        }

        .el-form-item__content {
          .el-button {
            width: 110px;
          }
        }
      }
    }
  }

  .personal-user-icon {
    /deep/ .el-dialog {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin: 0 !important;
      width: auto;

      .el-dialog__header {
        display: none;
      }

      .el-dialog__body {
        padding: 50px 50px 45px;
      }

      .el-dialog__footer {
        padding: 0 50px 30px;
        text-align: center;

        .el-button {
          width: 110px;
        }
      }

      .user-icon-lists-wrap {
        padding-right: 10px;
        height: 472px;
        overflow-y: auto;

        &::-webkit-scrollbar {
          /*滚动条整体样式*/
          width: 7px;
          /*高宽分别对应横竖滚动条的尺寸*/
          height: 4px;
          background-color: #f2f2f2;
        }

        &::-webkit-scrollbar-thumb {
          /*滚动条里面小方块*/
          border-radius: 7px;
          box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
          background: rgba(0, 0, 0, 0.2);
          background-color: #dee0e2;
        }

        &::-webkit-scrollbar-track {
          /*滚动条里面轨道*/
          box-shadow: inset 0 0 5px #f2f2f2;
          border-radius: 0;
          background: #f2f2f2;
        }

        .user-icon-lists {
          width: 640px;
          overflow: hidden;

          .item {
            position: relative;
            float: left;
            margin-right: 50px;
            margin-bottom: 40px;

            &:nth-of-type(5n) {
              margin-right: 0;
            }

            .user-icon {
              display: block;
              width: 88px;
              height: 88px;
              z-index: 8;
              cursor: pointer;
            }

            .active-icon {
              position: absolute;
              top: 0;
              right: 0;
              width: 32px;
              height: 32px;
              background-image: url('../../assets/image/user-icon-active.png');
              z-index: 9;
            }
          }
        }
      }
    }
  }
}
</style>
